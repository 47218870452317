<template>
  <div class="bac">
    <div class="main">
      <div class="head">
        <div>
          <a>系统消息</a>
          <i></i>
        </div>
      </div>
      <div class="content">
        <el-scrollbar max-height="550px">
          <div v-for="(item ,i) in this.Info">
            <div class="comment">
                <div class="tx"></div>
                <div class="info">
                  <div class="info_top">
                    <div class="name">{{ item.title }}</div>
                    <div class="years">{{ item.publishTime }}</div>
                  </div>
                  <div class="info_bottom">
                    <input type="text" :value="item.content">
                  </div>
                </div>
              </div>
          </div>
        </el-scrollbar>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  name: "SystemMessage",
  data(){
    return {
      Info: ''
    }
  },
  mounted() {
    this.getInf();
  },
  methods:{
    getInf() {
      this.$http({
        method: 'get',
        url: '/allInformationForUser',
        data: ''
      }).then(res => {
        this.Info = res.data;
      })
    }
  }
}
</script>

<style scoped>

.bac{
  width: 1920px;
  padding-left: 298px;
  background-color: #f5f5f5;
}

.main{
  float: left;
  width: 1458px;
  height: 764px;
  margin-left: 66px;
  margin-top: 72px;
  background-color: #ffffff;
  border: 1px solid transparent;
}

.main .head{
  width: 1400px;
  height: 96px;
  margin-left: 30px;
}

.head div{
  width: 122px;
  height: 96px;
  /*font-size: 28px;*/
  /*color: #ec414d;*/
}

.head div a{
  display: block;
  width: 122px;
  height: 94px;
  line-height: 96px;
  font-size: 28px;
  color: #ec414d;
  text-align: center;
}

.head div i{
  display: block;
  width: 122px;
  height: 2px;
  background-color: #ec414d;
}

.main .content{
  width: 1458px;
  height: 668px;
  padding-top: 54px;
}

.content .comment{
  width: 1165px;
  height: 86px;
  margin-left: 96px;
  margin-bottom: 24px;
}

.content .comment .tx{
  float: left;
  width: 86px;
  height: 86px;
  border-radius: 50%;
  /*background-color: pink;*/
  margin-right: 24px;
  background-image: url("../../../../images/tx.png");
  background-size: 100% 100%;
}

.content .comment .info{
  float: left;
  width: 1055px;
  height: 86px;
}

.content .info .info_top{
  width: 1055px;
  height: 22px;
  margin-bottom: 17px;
}

.content .info .info_top .name{
  float: left;
  font-size: 18px;
  color: #212121;
  margin-right: 24px;
  font-weight: 500;
}

.content .info .info_top .years{
  float: left;
  font-size: 18px;
  color: #676364;
  margin-right: 12px;
}

.content .info .info_top .time{
  float: left;
  font-size: 18px;
  color: #676364;
}

.content .info .info_bottom{
  width: 1055px;
  height: 64px;
}

.content .info .info_bottom input{
  width: 1055px;
  height: 48px;
  padding-left: 30px;
  color: #676364;
  font-size: 18px;
  border: 1px solid #676364;
}
</style>